import React from 'react'
import { FaLinkedinIn, FaYoutube, FaFacebookF, FaBlog } from 'react-icons/fa'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import {
  Container,
  Top,
  Form,
  FormInfo,
  Links,
  Bottom,
  Rights,
  Social,
} from './styles'
import ContactForm from '../../sections/Index/Content/ContactForm'

function Footer() {
  return (
    <Container id="contato">
      <Top>
        <Form>
          <FormInfo>
            <h2>
              <strong>
                SOLICITE <br />
                INFORMAÇÕES
              </strong>
              <br />
              DO PLANO PARA SUA <br />
              EMPRESA
            </h2>
            <p>
              <strong>
                O Master International não é um <br />
                seguro viagem.
              </strong>{' '}
              Esse é o primeiro e <br />
              único plano de saúde no Brasil com <br />
              cobertura em 190 países.
            </p>
            <b>
              SÓ COMERCIALIZAMOS PARA EMPRESAS A PARTIR DE 2 VIDAS. NÃO TEMOS
              PLANO INDIVIDUAL E FAMILIAR.
            </b>
          </FormInfo>
          <ContactForm />
        </Form>
        <Links>
          <div>
            <h3>
              EXPERIENCIA <br />
              EM SAÚDE
            </h3>
            <p>
              <Link to="#objetivos">Nosso objetivo</Link> <br />
              <Link to="#nosso-time">Nossa equipe</Link>
            </p>
          </div>
          <div>
            <h3>
              USA MEDICAL <br />
              SERVICES
            </h3>
            <p>
              <Link to="#contato">Linha direta para experiência médica</Link>
              <br />
              <Link to="#contato">Estamos ao alcance com um só contato</Link>
              <br />
              <Link to="#contato">
                Requisito de notificação à Bupa <br /> Disponível por 24h por
                dia
              </Link>
            </p>
          </div>
          <div>
            <h3>
              MASTER <br />
              INTERNATIONAL
            </h3>
            <p>
              <Link to="#contato">Atendimento no Brasil</Link>
              <br />
              <Link to="#contato">Emergências médicas</Link>
            </p>
          </div>
          <div>
            <h3>
              SUA <br />
              COBERTURA
            </h3>
            <p>
              <Link to="/caracteristicas">Cobertura geográfica</Link>
              <br />
              <Link to="/caracteristicas">Tabela de benefícios</Link>
              <br />
              <Link to="/caracteristicas">Care Plus Master International</Link>
            </p>
          </div>
        </Links>
      </Top>
      <Bottom>
        <Rights>
          © Care Plus 2023. Todos os direitos reservados | ANS nº 37995-6 <br />
        </Rights>
        <Social>
          <OutboundLink
            href="https://www.linkedin.com/company/care-plus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn size={20} color="#AE9962" />
          </OutboundLink>
          <OutboundLink
            href="https://www.youtube.com/channel/UCCUpIyfCQXl39-O7WMudf2Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={20} color="#AE9962" />
          </OutboundLink>
          <OutboundLink
            href="https://www.facebook.com/careplusnarede"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={20} color="#AE9962" />
          </OutboundLink>
          <OutboundLink
            href="https://careplusmais.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaBlog size={20} color="#AE9962" />
          </OutboundLink>
        </Social>
      </Bottom>
    </Container>
  )
}

export default Footer
