import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Container, Logos, Menu, MenuButton } from './styles'
import bupa from '../../images/bupa-logo.png'
import blue from '../../images/bluecross-blueshield-global-logo.png'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]')
}

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  function handleOpenMenu() {
    setIsOpen(!isOpen)
  }

  function setActiveName(path) {
    return pathname === path ? 'active' : ''
  }

  return (
    <Container>
      <Logos>
        <Link to="/">
          <img src={bupa} alt="Bupa" title="Bupa" />
        </Link>
        <Link to="/">
          <img
            src={blue}
            alt="BlueCross BlueShield Global"
            title="BlueCross BlueShield Global"
          />
        </Link>
      </Logos>
      <MenuButton onClick={handleOpenMenu}>
        <FaBars size={22} color="#ae9962" />
      </MenuButton>
      <Menu isOpen={isOpen}>
        <MenuButton onClick={handleOpenMenu}>
          <FaTimes size={22} color="#fff" />
        </MenuButton>
        <ul>
          <li>
            <Link to="/quem-somos" className={setActiveName('/quem-somos')}>
              Quem Somos
            </Link>
          </li>
          <li>
            <Link to="#objetivos">Objetivos</Link>
          </li>
          <li>
            <Link to="#nosso-time" className={setActiveName('/nosso-time')}>
              Nosso Time
            </Link>
          </li>
          <li>
            <Link to="/caracteristicas">Características</Link>
          </li>
          <li>
            <Link to="#contato">Contato</Link>
          </li>
        </ul>
      </Menu>
    </Container>
  )
}

export default Header
