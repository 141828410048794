import styled, { css } from 'styled-components'
import {
  Form as FormAntd,
  Input as InputAntd,
  Checkbox as CheckboxAntd,
} from 'antd'
import MaskedInputCleave from 'cleave.js/react'

export const Form = styled(FormAntd).attrs({
  colon: false,
  layout: 'vertical',
})`
  width: 55.462%;

  > div {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      width: 45.7%;
    }
  }

  > .ant-form-item {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-required::before {
    color: rgb(174, 153, 98);
  }

  .ant-form-explain {
    font-size: 12px;
    color: #ff414c;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: white;
      border: 1px solid #d8dad9 !important;

      &:after {
        border-color: #ae9962;

        &:hover {
          border-color: #ae9962;
        }
      }
    }
  }

  button {
    width: 145px;
  }

  @media (max-width: 768px) {
    width: 70%;

    button {
      width: 100%;
    }
  }

  @media (max-width: 525px) {
    width: 90%;
  }
`

const baseInput = css`
  background: #ffffff !important;
  border: 1px solid #d8dad9 !important;
  height: 45px !important;
  width: 100% !important;
  padding: 4px 11px !important;
  border-radius: 4px !important;

  &:focus {
    outline: 0 !important;
    border: 1px solid #ae9962 !important;
  }
`

export const Input = styled(InputAntd)`
  ${baseInput}
`

export const Textarea = styled(InputAntd.TextArea)`
  ${baseInput}
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 100px !important;
  max-height: 300px !important;
`

export const Checkbox = styled(CheckboxAntd)``

export const MaskedInput = styled(MaskedInputCleave)`
  ${baseInput}
`
