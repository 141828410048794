import styled from 'styled-components'

export const Container = styled.header`
  min-height: 230px;
  display: flex;
  flex-direction: column;
  padding-top: 53px;
  width: 100%;
  max-width: 1049px;
  height: auto;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1048px) {
    padding: 53px 15px 0 15px;
  }

  @media (max-width: 468px) {
    min-height: fit-content;
  }

  @media (max-width: 312px) {
    padding-top: 10px;
  }
`

export const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 312px) and (max-width: 568px) {
    a {
      &:first-child {
        img {
          height: 70px;
        }
      }

      &:last-child {
        img {
          height: 60px;
        }
      }
    }
  }

  @media (max-width: 468px) {
    flex-direction: column;

    a {
      img {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
`

export const Menu = styled.div`
  > div {
    padding-top: 0;
    justify-content: flex-start;
  }

  ul {
    display: flex;
    justify-content: flex-end;

    li {
      padding: 29px 0;

      a {
        font-size: 17px;
        line-height: 20px;
        color: #333333;
        padding: 29px 26.5px 41px 26.5px;

        &:hover,
        &.active {
          opacity: 0.5;
        }
      }

      &:first-child a {
        padding-left: 0;
      }
      &:last-child a {
        padding-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    ul {
      justify-content: space-between;

      li a {
        padding: 0;
      }
    }
  }

  @media (max-width: 568px) {
    width: ${props => (props.isOpen ? '90%' : '0%')};
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    justify-content: flex-start;
    background: #ae9962;
    padding: ${props => (props.isOpen ? '40px 20px' : '0')};
    overflow: hidden;

    ul {
      margin-top: 50px;
      flex-direction: column;

      li a {
        font-size: 17px;
        color: #fff;
      }
    }

    li:first-child {
      padding-top: 0;
    }
  }

  @media (max-width: 368px) {
    width: ${props => (props.isOpen ? '90%' : '0%')};
  }
`

export const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 0;

  @media (min-width: 568px) {
    display: none;
  }
`
