import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Styles from './styles'

function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Styles />
    </>
  )
}

export default Layout
