import styled from 'styled-components'
import { Link } from 'gatsby'

export const Container = styled(Link).attrs(props => ({
  to: props.to,
}))`
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  padding: 14.5px 28px;
  width: fit-content;
  display: flex;
  justify-content: center;

  &:hover {
    color: #fff;
    background: #836f3d;
  }
`
