import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Block, Message, Path } from './styles'
import ButtonOutlineWhite from '../ButtonOutlineWhite'

function Banner({ pageTitle, heading, height, filename }) {
  const [showButton, setShowButton] = useState(true)
  const [path, setPath] = useState('')

  useEffect(() => {
    const { pathname } = window.location
    setShowButton(pathname === '/masterinternational/')
    setPath(pathname)
  }, [])

  return (
    <StaticQuery
      query={graphql`
        {
          banners: allFile(
            filter: { extension: { eq: "png" }, name: { regex: "/-banner/" } }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const banner = data.banners.edges.find(b => b.node.name === filename)

        return (
          <>
            <Container
              Tag="div"
              id="banner"
              fluid={banner.node.childImageSharp.fluid}
              height={height}
            >
              <div>
                <Block>
                  <h2>{heading}</h2>
                  {showButton && (
                    <ButtonOutlineWhite to="/caracteristicas">
                      Saiba Mais
                    </ButtonOutlineWhite>
                  )}
                </Block>
              </div>
            </Container>
            {path !== '/masterinternational/' && (
              <Path>
                <p>
                  Início » <strong>{pageTitle}</strong>
                </p>
              </Path>
            )}
            <Message>Um plano de saúde com cobertura internacional.</Message>
          </>
        )
      }}
    />
  )
}

Banner.propTypes = {
  bannerHeight: PropTypes.number,
  pageTitle: PropTypes.string.isRequired,
  heading: PropTypes.string,
  height: PropTypes.number,
  filename: PropTypes.string.isRequired,
}

Banner.defaultProps = {
  heading: 'MASTER INTERNATIONAL É OUTRO NÍVEL',
  height: 250,
}

export default Banner
