import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'

function Button({ to, className, type, onClick, children }) {
  return (
    <Container to={to} className={className} type={type} onClick={onClick}>
      {children}
    </Container>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  to: '#',
  type: 'button',
  onClick: () => {},
}

export default Button
