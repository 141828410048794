import { createGlobalStyle } from 'styled-components'
import gothamBook from '../../fonts/Gotham-Book.woff2'
import gothamLight from '../../fonts/Gotham-Light.woff2'
import 'antd/dist/antd.css'

export default createGlobalStyle`
  @font-face {
    font-family: 'Gotham';
    src: url(${gothamBook});
  }

  @font-face {
    font-family: 'Gotham Light';
    src: url(${gothamLight});
  }

  * {
    outline: none;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    font-family: 'Gotham';
  }

  html,
  body {
    margin: 0;
    padding: 0;
    outline: none;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  ul {
    list-style: none;
  }

  ul,
  a,
  img,
  p,
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  button,
  select {
    border: none;
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:visited {
      color: inherit;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
`
