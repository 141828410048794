import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export const Container = styled(BackgroundImage)`
  min-height: ${props => `${props.height}px`};
  display: flex;

  img {
    height: ${props => `${props.height}px`};
  }

  > div {
    position: relative;
    width: 100%;
    max-width: 1045px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: ${props => `${props.height}px`};

    ${props =>
      props.height === 250 &&
      css`
        div {
          max-width: 280px !important;
          max-height: 200px !important;

          h2 {
            font-size: 22px !important;
            line-height: 26px !important;
          }
        }
      `}
  }

  &:before {
    background-position: center 10%, center, center !important;
  }

  @media (max-width: 1048px) {
    > div {
      padding-left: 15px;
    }
  }

  @media (max-width: 470px) {
    min-height: fit-content;
  }
`

export const Message = styled.div`
  background: #ae9962;
  text-align: center;
  padding: 25px 0px;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  font-family: 'Gotham Light';
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  padding: 24px;
  background: #ae9962;
  width: 100%;
  max-width: 304px;
  height: 100%;
  max-height: 304px;

  h2 {
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Gotham Light';
  }

  @media (max-width: 325px) {
    width: 90%;
  }
`

export const Path = styled.div`
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #333333;
  background: #ecf5f3;

  p {
    width: 100%;
    max-width: 1335px;
    margin: 16px auto;
  }

  @media (max-width: 1368px) {
    p {
      padding: 0px 15px;
    }
  }

  @media (max-width: 568px) {
    p {
      text-align: center;
    }
  }
`
