import 'cleave.js/src/addons/phone-type-formatter.br'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import ReCaptcha from 'react-google-recaptcha'
import { Checkbox, Form, Input, MaskedInput, Textarea } from './styles'

function ContactForm({ form }) {
  const [url, setUrl] = useState()

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue,
    resetFields,
  } = form

  function reset() {
    resetFields([
      'nome',
      'sobrenome',
      'empresa',
      'email',
      'telefone',
      'mensagem',
      'has2lives',
    ])
  }

  function onSubmit(e) {
    e.preventDefault()
    validateFieldsAndScroll(err => {
      if (!err) {
        document.getElementById('contact-form').submit()
      }
    })
  }

  const handleChange = (campo) => (e) => {
    handleNameChange(campo, e);
  };

  const handleNameChange = (campo, e) => {
    const cleanedValue = e.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');
    const isInput = e.target.tagName.toLowerCase() === 'input';
    const isTextArea = e.target.tagName.toLowerCase() === 'textarea';

    if (isInput) {
      document.querySelector(`[name='${campo}']`).value = cleanedValue;
    } else if (isTextArea) {
      document.querySelector("textarea").value = cleanedValue;   
    }
  };
  

  return (
    <Form
      id="contact-form"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="oid" value="00D300000001VCx" />
      <input type="hidden" name="recordType" value="012f2000000QLDp" />
      <input type="hidden" name="retURL" value={`${url}obrigado`} />

      <div>
        <Form.Item label="Nome">
          {getFieldDecorator('first_name', {
            initialValue: '',
            rules: [
              { required: true, message: 'Preencha o seu nome' }
            ],
          })(
            <Input
              id="first_name"
              name="first_name"    
              onChange={handleChange('first_name')}        
            />
          )}
        </Form.Item>

        <Form.Item label="Sobrenome">
          {getFieldDecorator('last_name', {
            initialValue: '',
            rules: [{ required: true, message: 'Preencha o seu sobrenome' }],
          })(
            <Input
              id="last_name"
              name="last_name"
              onChange={handleChange('last_name')}
            />
          )}
        </Form.Item>
      </div>

      <Form.Item label="Empresa">
        {getFieldDecorator('company', {
          initialValue: '',
          rules: [{ required: true, message: 'Preencha a sua empresa' }],
        })(
          <Input
            id="company"
            name="company"
            onChange={handleChange('company')}    
          />
        )}
      </Form.Item>

      <div>
        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            initialValue: '',
            rules: [
              { required: true, message: 'E-Preencha o seu e-mail' },
              { type: 'email', message: 'O e-mail é inválido' },
            ],
          })(
            <Input
              id="email"
              name="email"
              onChange={e => setFieldsValue({ email: e.target.value })}
            />
          )}
        </Form.Item>

        <Form.Item label="Telefone">
          {getFieldDecorator('phone', {
            initialValue: '',
            rules: [
              { required: true, message: 'Preencha o seu telefone' },
              { len: 13, message: 'Telefone deve conter todos os números' },
            ],
          })(
            <MaskedInput
              id="phone"
              name="phone"
              options={{ phone: true, phoneRegionCode: 'br' }}
              onChange={e => setFieldsValue({ telefone: e.target.value })}
            />
          )}
        </Form.Item>
      </div>

      <Form.Item label="Mensagem">
        {getFieldDecorator('description', {
          initialValue: '',
          rules: [{ required: true, message: 'Mensagem é obrigatória' }],
        })(
          <Textarea
            id="description"
            name="description"
            onChange={handleChange('')}   
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('has2lives', {
          valuePropName: 'checked',
          initialValue: false,
          rules: [
            {
              required: true,
              transform: value => value || undefined,
              type: 'boolean',
              message: 'Você precisa confirmar o termo acima.',
            },
          ],
        })(
          <Checkbox name="check" checked={getFieldValue('has2lives')}>
            Minha empresa tem a partir de 2 vidas e não tem vínculo familiar.
          </Checkbox>
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('recaptcha', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: 'Você precisa resolver o ReCaptcha',
            },
          ],
        })(
          <ReCaptcha
            sitekey="6LejyxkmAAAAACBq32lfDJvfl_0j45oLZlcz2yMI"
            onExpired={() => setFieldsValue({ recaptcha: '' })}
          />
        )}
      </Form.Item>

      <button type="submit">Enviar</button>
    </Form>
  )
}

ContactForm.propTypes = {
  form: PropTypes.shape().isRequired,
}

export default Form.create({ name: 'contact-form' })(ContactForm)
