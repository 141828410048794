import styled from 'styled-components'
import Button from '../Button'

export default styled(Button).attrs(props => ({
  to: props.to,
}))`
  border: 1px solid #fff;
  background: transparent;
  color: #fff !important;

  &:hover {
    background: #836f3d;
    border: 1px solid #836f3d;
  }
`
