import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-top: 98px;
  min-height: fit-content;
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  background: #687b70;
  padding-top: 98px;
  min-height: fit-content;

  @media (max-width: 768px) {
    padding-top: 49px;
  }
`

export const Form = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f7f6f6;
  width: 100%;
  max-width: 1045px;
  margin: auto;
  padding: 51px;

  button,
  .btn-disabled {
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    padding: 14.5px 28px;
    width: fit-content;
    display: flex;
    justify-content: center;
    background: #ae9962;
    border: 1px solid #ae9962;
    color: #fff !important;

    &:hover {
      color: #fff;
      background: #836f3d;
    }
  }

  .btn-disabled {
    background: #cecece;
    color: #777;
    border: 1px solid #cecece;

    &:hover {
      background: #cecece;
      color: #777;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 51px 10px;
  }

  @media (max-width: 425px) {
    padding: 15px 10px;
  }
`

export const FormInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 35.95%;

  h2 {
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #ae9962;
    margin-bottom: 45px;
  }

  p {
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 54px;
  }

  b {
    border: 1px dashed #c4c4c4;
    font-size: 12px;
    line-height: 14px;
    padding: 26px 32px;
    text-align: center;
    color: #ae9962;
  }

  @media (max-width: 868px) {
    width: 45.95%;
  }

  @media (max-width: 768px) {
    width: 75%;
    text-align: center;
    margin-bottom: 51px;
  }

  @media (max-width: 512px) {
    width: 95%;
  }

  @media (max-width: 425px) {
    h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 28px;
    }
  }
`

export const FormFields = styled.form`
  display: flex;
  flex-direction: column;
  width: 55.462%;

  input {
    margin-bottom: 22px;
  }

  button,
  .btn-disabled {
    width: 144px;
    margin-top: 27px;
  }

  @media (max-width: 868px) {
    width: 49.462%;
  }

  @media (max-width: 768px) {
    width: 75%;

    button,
    .btn-disabled {
      align-self: center;
    }
  }

  @media (max-width: 512px) {
    width: 95%;
  }

  @media (max-width: 368px) {
    button,
    .btn-disabled {
      width: 100%;
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div:not(:last-of-type) {
    margin-right: 25px;
  }

  @media (max-width: 368px) {
    div:not(:last-of-type) {
      margin-right: 0;
    }

    flex-direction: column;
  }
`

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1045px;
  margin: auto;
  padding-top: 67px;
  margin-bottom: 81px;

  h3 {
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    font-weight: bold;
  }

  p {
    font-weight: 325;
    font-size: 15px;
    line-height: 28px;
    opacity: 0.7;
    margin-top: 36px;

    a {
      color: #ffffff;
    }

    a:hover {
      opacity: 0.7;
    }
  }

  div:not(:last-child) {
    margin-right: 70px;
  }

  @media (min-width: 642px) {
    p {
      a {
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 1048px) {
    padding: 67px 15px;
    margin-bottom: 0;
  }

  @media (max-width: 868px) {
    margin-bottom: 0;
    div:not(:last-child) {
      margin-right: 40px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 51px 20px;

    p {
      margin-top: 10px;
    }

    div {
      flex: 0 0 50%;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: 425px) {
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: center;
  }
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  width: 100%;
  max-width: 1045px;
  margin: auto;

  @media (max-width: 1048px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    padding-top: 20px;
  }
`

export const Rights = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: #333333;

  a {
    color: #ae9962 !important;
    font-size: 14px;
    margin-top: 7px;
    display: inline-block;
    margin-right: 20px;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 1048px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
  }
`

export const Social = styled.div`
  display: flex;
  align-items: center;

  a {
    height: 31px;
    padding: 5px 6px;

    &:hover {
      background: #ae9962;
      border-radius: 50%;

      svg {
        color: #fff !important;
      }
    }

    &:not(:last-child) {
      margin-right: 31px !important;
    }
  }

  @media (max-width: 1048px) {
    a:not(:last-child) {
      margin-right: 22px;
    }
  }

  @media (max-width: 768px) {
    width: 40%;
    margin: 10px;
    justify-content: space-around;
  }
`
